
import React,  { useEffect, useRef, useState } from "react";
import { Backdrop, } from "@material-ui/core";
import styles from "./ProgressLoader.module.css";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const ProgressLoader = ({ open, completeProgress }) => {
    const [progress, setProgress] = useState(0);
    const intervalRef = useRef(null); // Use useRef to keep track of the interval
    const [openLoading, setOpenLoading] = useState(false);
    
    useEffect(() => {
        if(open){
            setOpenLoading(true)
            intervalRef.current = setInterval(() => {
                setProgress((prevCount) => {
                    if (prevCount < 99) {
                        return prevCount + 1;
                    } else {
                        clearInterval(intervalRef.current); // Stop the interval when count reaches 100
                        return prevCount;
                    }
                });
            }, 100); // Adjust the interval timing as needed
        
            return () => clearInterval(intervalRef.current); // Cleanup on component unmount
        }
    }, [open]);

    useEffect(()=>{
        if(completeProgress && !open){
            setProgress(100); // Manually set count to 100
            clearInterval(intervalRef.current); // Stop the interval
            setTimeout(()=>{
                setOpenLoading(false)
                setProgress(0); // Manually set count to 0
                intervalRef.current = null
            },500)
        }
        
    },[open, completeProgress])

    return <>
        <Backdrop className={styles.backdrop} open={openLoading}>
            <Box position="relative" display="inline-flex">
                <Box
                    top={0}
                    left={0}
                    bottom={'100px'}
                    right={0}
                    zIndex={9}
                    position="fixed"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="caption" className={styles.note}>
                        Please wait while the transaction list is being refreshed.
                    </Typography>
                </Box>
                <CircularProgress variant="determinate" value={progress} size={60} className={styles.icon}/>
                <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography variant="caption" className={styles.progress}>
                        {progress}%
                    </Typography>
                </Box>
            </Box>
		</Backdrop>
    </>
}

export default ProgressLoader;