import { Box, Button, Grid, Paper, withStyles } from "@material-ui/core";
import { debounce } from "lodash";
import { useState } from "react";
import TransactionFilter from "./TransactionFilter/TransactionFilter";
import styles from "./Transactions.module.css";
import TransactionSummary from "./TransactionSummary/TransactionSummary";
import TransactionTable from "./TransactionTable/TransactionTable";
import HTTP from "../../helpers/ApiClient";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import FullPageLoader from "../common/FullPageLoader/FullPageLoader";
import { usePagination, useToggle } from "../../utils/hooks";
import SearchField from "../Inputs/SearchField";
import PrintReceiptFlow from "../PrintReceiptFlow/PrintReceiptFlow";
import HelpIcon from "@material-ui/icons/Help";
import { NavigationConfirmModal } from "react-router-navigation-confirm";
import { useHistory } from "react-router-dom";
import { togglePrintReceiptModal } from "../../redux/modules/receipt";
import { connect } from "react-redux";
import ProgressLoader from "../common/ProgressLoader/ProgressLoader";
import RefreshSharpIcon from '@mui/icons-material/RefreshSharp';
import { setRefreshList } from "../../redux/modules/transaction";

async function getTransactions(filters) {
	const {
		tpaid,
		dateFrom,
		dateTo,
		biller: serviceCode,
		status,
		paymentMethod: modeOfPayment,
		limit,
		page = 1,
		search,
		sort,
		sortBy,
	} = filters;
	const format = (date) => {
		if (!date) return undefined;
		return date.format("yyyy/MM/DD");
	};

	const resolveParam = (p) => p || undefined;

	try {
		const getDateRange = () => {
			if ([dateFrom, dateTo].some((d) => !d)) return undefined;
			console.log(
				`${format(dateFrom)} 00:00:00-${format(dateTo)} 23:59:00`
			);
			return `${format(dateFrom)} 00:00:00-${format(dateTo)} 23:59:00`;
		};
		const params = {
			serviceCode: resolveParam(serviceCode),
			modeOfPayment: resolveParam(modeOfPayment),
			status: resolveParam(status),
			dateFromAndTo: getDateRange(),
			page,
			limit,
			search: resolveParam(search),
			sortBy: resolveParam(sortBy),
			sort: resolveParam(sort),
			isTransactionHistory: true,
		};
		const result = await HTTP.get("/v3/transaction/list", {
			params,
		});
		if (!result || result.status != 200) return null;
		const {
			data: { count, data: items },
		} = result;
		return {
			count,
			items,
		};
	} catch (error) {
		console.error(error);
		return null;
	}
}

const TransactionList = ({
	page,
	rowsPerPage,
	data,
	onPageChange,
	onSort,
	filters,
	defaultFilters,
	onFilterSubmit,
	onFilterChange,
	isReloadLocked,
	isUserLoggingOut,
	handleRefetchTransaction,
	showLoader,
	hideLoader
}) => {
	let history = useHistory();
	let dispatch = useDispatch();

	const handleSearchChange = debounce((event) => {
		const {
			target: { value: search },
		} = event;
		onFilterChange({ ...filters, search });
		if (search.length >= 3 || (search === "" && filters.search !== "")) {
			onFilterSubmit({ search });
		}
	}, 500);

	const handleRefresh = () => {
		handleRefetchTransaction()
		dispatch(setRefreshList(true))
	}

	const onControlModalConfirmButton = () => {
		history.push("/pay-bills");
		dispatch(togglePrintReceiptModal(false));
	};

	return (
		<>
			<PrintReceiptFlow isReprint />
			{isReloadLocked && !isUserLoggingOut && (
				<NavigationConfirmModal
					onConfirm={onControlModalConfirmButton}
					confirmText="Continue"
					buttonClassName={styles.confirmModalButton}
					bodyClassName={styles.confirmModalBody}
				>
					<Grid container>
						<Grid item xs={2}>
							<HelpIcon
								style={{
									fontSize: "50px",
									color: "#4876B0",
									margin: "20px 10px 10px 10px",
								}}
							/>
						</Grid>
						<Grid item xs={10}>
							<p>
								The page that you're looking for used
								information that you entered. Returning to that
								page might cause any action you took to be
								repeated. Do you want to continue?
							</p>
						</Grid>
					</Grid>
				</NavigationConfirmModal>
			)}

			<Grid container spacing={2} direction="column">
				<Grid
					container
					item
					justify="space-between"
					alignItems="center"
				>
					<Grid item xs={8}>
						<span className={styles.header}>Transaction List</span>
					</Grid>
					<Grid item xs={3}>
						<SearchField
							label="Account No. or Transaction Ref"
							onChange={handleSearchChange}
						/>
					</Grid>
					<Grid item xs={1} >
						<Button variant="outlined" 
							className={styles.refreshButton} 
							onClick={handleRefresh} 
							startIcon={
								<RefreshSharpIcon  className={styles.refresh}/>
							}
						>
							Refresh
						</Button>
					</Grid>
				</Grid>
				<Grid item xs>
					<TransactionFilter
						values={filters}
						initialValues={defaultFilters}
						onSubmit={onFilterSubmit}
						onChange={onFilterChange}
					/>
				</Grid>
				<Grid item xs>
					<TransactionTable
						page={page}
						handleRefetchTransaction={handleRefetchTransaction}
						rowsPerPage={rowsPerPage}
						data={data}
						onPageChange={onPageChange}
						onSort={onSort}
						sortBy={filters.sortBy}
						sort={filters.sort}
						showLoader={showLoader}
						hideLoader={hideLoader}
					/>
				</Grid>
			</Grid>
		</>
	);
};

TransactionList.propTypes = {
	page: PropTypes.number,
	rowsPerPage: PropTypes.number,
	data: PropTypes.arrayOf(PropTypes.object),
	onPageChange: PropTypes.func,
	onSort: PropTypes.func,
	filters: PropTypes.object,
	defaultFilters: PropTypes.object,
	onFilterSubmit: PropTypes.func,
	onFilterChange: PropTypes.func,
	isReloadLocked: PropTypes.bool,
	isUserLoggingOut: PropTypes.bool,
	handleRefetchTransaction: PropTypes.func,
	showLoader: PropTypes.func,
	hideLoader: PropTypes.func
};

const Transactions = ({ isReloadLocked, isUserLoggingOut }) => {
	const { page, setPage, rowsPerPage, setRowsPerPage } = usePagination(25);
	const [transactions, setTransactions] = useState({
		count: 0,
		items: [],
	});
	const terminalId = useSelector((state) => state.userInfo.terminal.name);
	const defaultFilters = {
		tpaid: terminalId,
		dateFrom: null,
		dateTo: null,
		status: "",
		paymentMethod: "",
		sortBy: "createdAt",
		sort: "desc",
	};

	const [filters, setFilters] = useState(defaultFilters);

	const [isInitialized, initialize] = useToggle();
	const [isTimeoutError, showTimeoutError, hideTimeoutError] = useToggle();
	const [isLoading, showLoader, hideLoader] = useToggle(true);

	const submitFilters = useCallback(
		async (values = filters, page = 1, limit = rowsPerPage) => {
			setTransactions({
				count: 0,
				items: [],
			})
			showLoader();
			const result = await getTransactions({
				...values,
				tpaid: terminalId,
				limit,
				page,
			});

			if (result === null) {
				showTimeoutError();
				hideLoader();
				return;
			}
			setTransactions(result);
			hideLoader();
		},
		[
			filters,
			hideLoader,
			rowsPerPage,
			showLoader,
			showTimeoutError,
			terminalId,
		]
	);

	const handleSort = (sortBy, sort) => {
		const updatedFilters = { ...filters, sortBy, sort };
		setFilters(updatedFilters);
		submitFilters(updatedFilters, page, rowsPerPage);
		console.log({ sortBy, sort });
	};

	useEffect(() => {
		if (!isInitialized) {
			submitFilters();
			initialize();
		}
	}, [submitFilters, isInitialized, initialize]);

	const handleFilterChange = (values) => {
		setFilters(values);
	};
	const handleFilterSubmit = async (values = {}) => {
		//Change page to 1 when filtering
		setPage(1);
		await submitFilters(values, 1, rowsPerPage);
	};

	const handlePageChange = (page, limit) => {
		setPage(page);
		setRowsPerPage(limit);
		submitFilters(filters, page, limit);
	};


	const handleRefetchTransaction = useCallback(() => {
		submitFilters(filters, page, rowsPerPage);
	}, [filters, page, rowsPerPage, submitFilters]);

	return (
		<>
			<TransactionList
				page={page}
				rowsPerPage={rowsPerPage}
				data={transactions}
				onPageChange={handlePageChange}
				onSort={handleSort}
				filters={{ tpaid: terminalId, ...filters }}
				defaultFilters={defaultFilters}
				onFilterChange={handleFilterChange}
				onFilterSubmit={handleFilterSubmit}
				isReloadLocked={isReloadLocked}
				isUserLoggingOut={isUserLoggingOut}
				handleRefetchTransaction={handleRefetchTransaction}
				showLoader={showLoader}
				hideLoader={hideLoader}
			/>
			{/* <FullPageLoader open={isLoading} /> */}
			<ProgressLoader open={isLoading} completeProgress={!isLoading}/>
			
		</>
	);
};

Transactions.propTypes = {
	isReloadLocked: PropTypes.bool,
	isUserLoggingOut: PropTypes.bool,
};

export default connect((state) => ({
	isReloadLocked: state.timeout.isReloadLocked,
	isUserLoggingOut: state.timeout.isUserLoggingOut,
}))(Transactions);
