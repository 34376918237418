import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	measureLatency,
	resetLatency,
	setNetworkOnline,
	setInitialLoad,
} from "../../redux/modules/latency";
import CustomSnackbar from "../common/Snackbar/Snackbar";
import { ReactComponent as SlowIcon } from "../../assets/icons/network/slow.svg";
import { ReactComponent as OfflineIcon } from "../../assets/icons/network/offline.svg";
import { ReactComponent as GoodIcon } from "../../assets/icons/network/good.svg";
import { showAlert } from "../../redux/modules/alert";

const LATENCY_CHECK_INTERVAL_MS = 10000; // 10 SEC
const SLOW_CONNECTION_THRESHOLD = 500;
const NOTIFICATION_THRESHOLD_HOURS = 3;
const LAST_NOTIFICATION_KEY = "lastNotificationTime";

const ConnectivityAlert = () => {
	const dispatch = useDispatch();
	const latency = useSelector((state) => state.latency?.latency ?? null);
	const status = useSelector((state) => state.latency?.status ?? "idle");
	const error = useSelector((state) => state.latency?.error ?? null);

	const isNetworkOnline = useSelector((state) => state.latency.networkOnline);

	const [isPreviouslyOffline, setPreviouslyOffline] = useState(true);

	const hasTimePassedSinceLastNotification = (lastNotificationTime, currentTime) => {
		if (!lastNotificationTime) return true; // No notification has been sent yet
		const threeHoursInMs = NOTIFICATION_THRESHOLD_HOURS * 60 * 60 * 1000;
		return currentTime - lastNotificationTime > threeHoursInMs;
	  };

	const handleConnectionRestoredAlert = () => {
		dispatch(
			showAlert({
				message: "Your internet connection has been restored",
				icon: <GoodIcon />,
			})
		);
		setPreviouslyOffline(false);
	};

	const handleConnectionOfflineAlert = () => {
		dispatch(
			showAlert({
				message: "Your internet connection was lost",
				icon: <OfflineIcon />,
			})
		);
	};

	const handleSlowConnectionAlert = () => {
		const lastNotificationTime = localStorage.getItem(LAST_NOTIFICATION_KEY);
    	const currentTime = Date.now();

		// Only notify if N hours have passed since the last notification
		if (hasTimePassedSinceLastNotification(lastNotificationTime,currentTime)) {
		dispatch(
			showAlert({
			message: "Your internet connection is slow",
			icon: <SlowIcon />,
			})
		);
		// Update the last notification timestamp
		localStorage.setItem(LAST_NOTIFICATION_KEY, currentTime);
    }
	};

	const handleOnline = () => {
		dispatch(setInitialLoad(true));
		dispatch(setNetworkOnline(true));
		if (isPreviouslyOffline) {
			handleConnectionRestoredAlert();
			setPreviouslyOffline(false);
		}
	};

	const handleOffline = () => {
		setPreviouslyOffline(true);
		handleConnectionOfflineAlert();
		dispatch(resetLatency());
		dispatch(setNetworkOnline(false));
	};

	// Trigger API latency API
	useEffect(() => {
		let intervalIdentifier;

		if (isNetworkOnline) {
			intervalIdentifier = setInterval(() => {
				dispatch(measureLatency());
			}, LATENCY_CHECK_INTERVAL_MS);
		} else {
			// Ensure that any ongoing interval is cleared when the network goes offline
			if (intervalIdentifier) clearInterval(intervalIdentifier);
		}
		// Cleanup function to clear interval on component unmount or isNetworkOnline change
		return () => {
			if (intervalIdentifier) clearInterval(intervalIdentifier);
		};
	}, [isNetworkOnline, dispatch]);

	// Trigger for Online/Offline
	useEffect(() => {
		window.addEventListener("online", handleOnline);
		window.addEventListener("offline", handleOffline);

		// Clean up the event listeners on component unmount
		return () => {
			window.removeEventListener("online", handleOnline);
			window.removeEventListener("offline", handleOffline);
		};
	}, [handleOnline, handleOffline]);

	// Watches latency value
	useEffect(() => {
		if (latency === null) {
			return; // Error case already handled
		} else if (latency > SLOW_CONNECTION_THRESHOLD) {
			handleSlowConnectionAlert();
		}
	}, [latency]);

	return (
		<div>
			<CustomSnackbar />
		</div>
	);
};

export default ConnectivityAlert;
