const sleep = async (sec: number) =>
	await new Promise((r) => setTimeout(r, sec * 1000));

const removeSpecialCharAndSpace = (val) => {
	return val.replace(/[^a-zA-Z0-9]/g, '');
}

const isAlphanumericAndString = (val) => {
  const alphanumericRegex = /^[a-z0-9]+$/i;
  return typeof val !== 'string' || alphanumericRegex.test(val) 
}

export { sleep, removeSpecialCharAndSpace, isAlphanumericAndString};


